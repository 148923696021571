import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import Color from 'color';
import { Observable, of, pipe, UnaryFunction } from 'rxjs';
import { catchError, shareReplay, tap } from 'rxjs/operators';
import { SettingsColors } from 'library-explorer';

import { 
  BundleType, 
  EntityTypeId, 
  FieldName, 
  FontFormat, 
  ImageModel,
  MaintenanceInterceptor,
  SettingsModel, 
  SessionStorageService, 
  FileUploadApiService, 
  HttpService, 
  OfflineModeService,
  BaseSettingsGetService,
  interceptorsToken,
  LoginIdentificationType
} from 'library-explorer';
import { LoginType } from '@app/model/enums/login-type.enum';


@Injectable({
  providedIn: 'root'
})
export class SettingsService implements BaseSettingsGetService {
  public initialized = false;
  public primaryColor = '#0062D0';
  public primaryTextColor = '#ffffff';
  private settings: Observable<SettingsModel> | undefined;

  private renderer: Renderer2;

  private readonly styleTagId = 'pel-style-sheets';
  private readonly fontStyleTagId = 'pel-font-style-sheets';
  private readonly fontFormatOrder = ['embedded-opentype', 'woff2', 'woff', 'opentype', 'truetype', 'svg'];

  private cachePipe: UnaryFunction<any, any> = pipe(
    shareReplay(1),
    catchError(err => {
      this.settings = undefined;
      this.initialized = false;

      throw err;
    })
  );

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private httpService: HttpService,
    private http: HttpClient,
    private title: Title,
    private rendererFactory: RendererFactory2,
    private offlineModeService: OfflineModeService,
    private sessionStorageService: SessionStorageService,
    private readonly fileUploadService: FileUploadApiService
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  public clearCache() {
    this.clearSettings();
  }

  public clearSettings() {
    this.settings = undefined;
    this.initialized = false;
  }

  getSettings() {
    if (!this.settings) {
      this.settings = this.fetchSettings();
    }

    return this.settings;
  }

  setSettings(settings: SettingsModel): void {
    this.settings = of(settings);
  }

  fetchSettings(): Observable<SettingsModel> {
    const jwtToken = this.sessionStorageService.getJwtTokenValue();
    const noHeaders = !jwtToken || this.sessionStorageService.isTokenExpired(jwtToken);

    let params = new HttpParams()
      .set(interceptorsToken.ENABLE_MAINTENANCE_IN_CASE_SERVER_ERROR_PARAM, true)
      .set(MaintenanceInterceptor.SKIP_MAINTENANCE_CHECKING_PARAM, true);

    if (noHeaders) {
      params = params.set(interceptorsToken.SKIP_TOKEN, true);
    }

    return this.httpService.get<SettingsModel>(`settings`, params, noHeaders)
      .pipe(
        this.cachePipe,
        tap((data: SettingsModel) => {
          this.initialize(data, params);
        }),
      );
  }

  public isRegistrationEnabled(settings: SettingsModel, checkForHiddenSetting = true): boolean {
    const { login: loginSettings, registration: registrationSettings }= settings;

    if (loginSettings?.identificationType === LoginIdentificationType.USERNAME || loginSettings?.type === LoginType.SSO) {
      return false;
    }

    if (checkForHiddenSetting && registrationSettings?.hide) {
      return false;
    }

    return registrationSettings?.enabled !== false;
  }

  public setPageTitle(title: string): void {
    this.title.setTitle(title);
  }

  private setPageFavicon(icon: ImageModel | undefined): void {
    if (!icon) {
      return;
    }

    const tag = this.document.getElementById('favicon-icon');
    const setFaviconAttribute = (src: string) => {
      this.renderer.setAttribute(tag, 'href', src);
    };

    this.fileUploadService.getPreSignedUrl(EntityTypeId.SETTINGS, BundleType.SETTINGS, FieldName.MEDIA_IMAGE, icon.key as string)
      .subscribe(setFaviconAttribute);
  }

  private async initialize(data: SettingsModel, params?: HttpParams): Promise<void> {
    if (this.initialized || !data) {
      return;
    }

    this.offlineModeService.storeRequest(`settings?${params.toString()}`, data);

    // this.setPageTitle(data.general.platformName);
    // this.setPageFavicon(data.general.faviconIcon);
    this.changeManifest(data);
    this.setBrandings(data);

    this.initialized = true;
  }

  private setBrandings(settings: SettingsModel): void {
    this.setColorsFromSettings(settings);

    const roundnessLevel = settings?.branding?.roundnessLevel || 0;
    const buttonRoundnessLevel = roundnessLevel >= 16 ? 100 : roundnessLevel;
  
    this.document.body.style.setProperty('--lms-base-border-radius', `${roundnessLevel}px`);
    this.document.body.style.setProperty('--lms-base-button-border-radius', `${buttonRoundnessLevel}px`);
  }

  private setMetaThemeColor(color: string): void {
    const head = document.getElementsByTagName('head')[0];
    const themeColorMeta = head.querySelector('meta[name="theme-color"]');

    if (!themeColorMeta) {
      return;
    }

    themeColorMeta.setAttribute("content", color);
  }

  private setColorsFromSettings(settings: SettingsModel): void {
    const c = settings.colors;
    const primaryColor = c.primaryColor || this.primaryColor;
    const primaryGray = Color(primaryColor).mix(Color('lightgray'), .55).lighten(0.2);
    const primaryLightGray = Color(primaryColor).lighten(0.8).mix(Color('#f3f5fb'), .9);
    const transparent = Color(primaryColor).fade(0.1);
    const primaryLight = Color(primaryColor).lighten(0.4);
    const primaryLightBorder = Color(primaryColor).alpha(0.3);
    const primaryLighter = Color(primaryColor).alpha(0.2);
    const textLightGray = Color(primaryColor).mix(Color('lightgray'), .4).lighten(0.05);
    this.primaryColor = c.primaryColor || '#0062D0';
    this.primaryTextColor = c.primaryTextColor || '#ffffff';

    const headerTransparent = 1 - ((c.headerTransparency || 0) / 100);
    let matMenuBackgroundColor = Color(c.headerColor || primaryColor);
    let headerColor: any = Color(c.headerColor || primaryColor).fade(headerTransparent);
    let themeColor = headerColor;
    const dark = Color(primaryColor).darken(0.2);
    let headerDark = Color(headerColor).isLight() ? Color(headerColor).darken(0.2) : Color(headerColor).lighten(0.2);

    if (Color(headerColor).isDark() && !Color(headerDark).luminosity()) {
      headerDark = Color(headerDark).lightness(10);
    }

    let headerButton = headerDark.fade(headerTransparent);

    if (c.headerGradient && c.headerGradient.enabled) {
      const { startColor, endColor, direction } = c.headerGradient;
      const start = Color(startColor).fade(headerTransparent);
      const end = Color(endColor).fade(headerTransparent);
      headerButton = end.darken(.9).fade(.8);

      headerColor = this.toGradient(start.toString(), end.toString(), direction || '');
      matMenuBackgroundColor = Color(endColor);
    }

    let css = `
    .header-background { background: ${headerColor || '#0062D0'} !important; color: ${c.headerIconColor || c.primaryTextColor} !important }
    .header .icon { color: ${c.headerIconColor} !important }
    .header-background .navigation-item,
    .header-background .header-button *,
    .header-background .platform-name,
    .primary-menu .primary-menu-button { color: ${c.headerIconColor || c.primaryTextColor} !important }
    .primary-menu .primary-button-dark { background: ${matMenuBackgroundColor} !important }
    .primary-menu .primary-button-dark-hover:hover { background: ${headerButton} !important }
    .primary-background.header-background .primary-header-text { color: ${c.headerIconColor || c.primaryTextColor} !important }
    .primary-background.header-background .primary-header-text  .material-icons { color: ${c.headerIconColor || c.primaryTextColor} !important }
    .primary-menu {
      background: ${matMenuBackgroundColor} !important;
    }
    .primary-menu, .primary-menu .mat-menu-item {
      color: ${c.headerIconColor || c.primaryTextColor} !important
    }
    .primary-background.header-background .mat-icon:not(.ignore-primary-color),
    .primary-menu .mat-icon,
    .primary-background.header-background .icon,
    .primary-background.header-background:before { fill: ${c.headerIconColor || c.primaryTextColor}; color: ${c.headerIconColor || c.primaryTextColor} !important }
    .primary-background.header-background .stroke-primary svg path {
      fill: ${c.headerIconColor || c.primaryTextColor};
      stroke: ${c.headerIconColor || c.primaryTextColor};
    }
    .stroke-primary svg path {
      fill: ${c.primaryColor || c.primaryTextColor};
      stroke: ${c.primaryColor || c.primaryTextColor};
    }
    .stroke-secondary svg path {
      stroke: ${c.secondaryColor};
    }
    .primary-background.header-background .primary-button-dark { background: ${headerButton} !important }
    .primary-background.header-background .primary-button-dark-hover:hover,
    .primary-menu { background: ${matMenuBackgroundColor} !important }
    .primary-background.header-background a.primary-border-dark {
      ${c.headerGradient && c.headerGradient.enabled && `border: none !important`}
    }
    .primary-background.header-background a.primary-button-dark-hover:hover {
      ${c.headerGradient && c.headerGradient.enabled && `background: ${headerButton} !important`}
    }
    .header-text-border { border-color: ${c.primaryTextColor} !important }
    .header-icon-border { border-color: ${c.headerIconColor || c.primaryTextColor} !important }
    .primary-background,
    .primary-button,
    .primary-progress-bar .mat-progress-bar-fill::after,
    .mat-expanded.panel-custom  .mat-expansion-panel-header,
    .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb,
    .custom-radio .mat-radio-checked .mat-radio-outer-circle,
    .active-link .rounded-circle,
    .active-link-responsive .rounded-circle { background-color: ${c.primaryColor || '#0062D0'} !important; color: ${c.primaryTextColor || '#ffffff'} !important }
    .primary-background *,
    .primary-button *,
    .primary-background-gradient,
    .primary-background-gradient *,
    .primary-background-gradient-hover:hover,
    .primary-background-gradient-hover:hover *,
    .primary-background-transparent-50,
    .primary-background-transparent-50 *,
    .mat-expanded.panel-custom .mat-expansion-panel-header *,
    .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb *,
    .custom-radio .mat-radio-checked .mat-radio-outer-circle *,
    .active-link .rounded-circle *,
    .active-link-responsive .rounded-circle * { color: ${c.primaryTextColor || '#ffffff'} !important }
    .tab-group-secondary .mat-ink-bar,
    .secondary-progress-bar .mat-progress-bar-fill::after,
    .mat-progress-bar-fill::after,
    .secondary-background,
    .secondary-button {  background-color: ${c.secondaryColor || '#FFAE00'} !important; color: ${c.secondaryTextColor || '#ffffff'} !important }
    .mat-ink-bar *,
    .primary-background-hover:hover .secondary-text-hover,
    .primary-background-hover:hover .secondary-background,
    .primary-background-hover:hover .secondary-background *,
    .secondary-text, .secondary-text-hover:hover,
    .secondary-background *,
    .secondary-button *,
    .secondary-background-gradient,
    .secondary-background-gradient *,
    .secondary-background-gradient-hover:hover,
    .secondary-background-gradient-hover:hover *,
    .secondary-background-gradient-child-hover:hover .gradient,
    .secondary-background-hover:hover * { color: ${c.secondaryTextColor || '#ffffff'} !important }
    .secondary-background-hover:hover { background-color: ${c.secondaryColor || '#0062D0'} !important }
    .secondary-color, .secondary-color *, .secondary-color-hover:hover { color: ${c.secondaryColor || '#0062D0'} !important }
    .secondary-background-gradient-child-hover:hover .gradient,
    .secondary-background-gradient { background: linear-gradient(to top, ${Color(c.secondaryColor)} 0%, ${Color(c.secondaryColor).alpha(.1)} 90%, ${Color(c.secondaryColor).alpha(.1)} 100%) }
    .secondary-background-gradient-hover:hover { background: linear-gradient(to top, ${Color(c.secondaryColor)} 0%, ${Color(c.secondaryColor).alpha(.1)} 90%, ${Color(c.secondaryColor).alpha(.1)} 100%) }
    .meph .mat-expansion-indicator:after,
    .primary-text, .primary-text-hover:hover { color: ${c.primaryTextColor || '#0062D0'} !important }
    .primary-color, .primary-color *, .primary-color-hover:hover { color: ${c.primaryColor || '#0062D0'} !important }
    .primary-border-dark{ border-color: ${dark} !important }
    .panel-header__hide { background-color: ${dark} !important }
    .secondary-border { border-color: ${c.secondaryColor || '#0062D0'} !important }
    .primary-background-expansion-panel.mat-expanded { background-color: ${c.primaryColor || '#0062D0'} !important; color: #ffffff !important }
    .primary-background-expansion-panel.mat-expanded .mat-expansion-indicator::after { color: #ffffff !important }
    .mat-progress-spinner.secondary-progress-spinner-color svg circle { stroke: ${c.secondaryColor || '#0062D0'} !important }
    .mat-progress-spinner.progress-spinner-white svg circle { stroke: white !important }
    .primary-border,
    .primary-border-hover:hover,
    .custom-radio .mat-radio-checked .mat-radio-outer-circle { border-color: ${c.primaryColor || '#0062D0'}  !important }
    .mat-progress-bar-buffer,
    .custom-radio .mat-radio-outer-circle,
    .primary-background-gray,
    .mat-slide-toggle.mat-checked .mat-slide-toggle-bar
    { background-color: ${primaryGray} !important; border-color: ${primaryGray} !important }
    .mat-expansion-indicator {  color: ${primaryGray} !important }
    .primary-button-dark { background-color: ${dark} !important }
    .primary-text-gray { color: ${primaryGray} !important }
    .primary-text-lightgray { color: ${textLightGray} !important }
    .panel-custom:not(.mat-expanded), .panel-custom:not(.mat-expanded) .panel-header-custom:hover,
    .primary-background-lightgray,
    .primary-background-lightgray-blur:not(:hover) {  background-color: ${primaryLightGray} !important; }
    .primary-background-transparent { background-color: ${transparent} !important}
    .primary-background-transparent-50 { background-color: ${Color(primaryColor).fade(0.5)} !important }
    .primary-background-transparent-15 { background-color: ${Color(primaryColor).fade(0.85)} !important }
    .primary-background-gradient-basic { background:  linear-gradient(to top,  ${c.primaryColor} 0%, transparent 100%) }
    .primary-background-gradient { background: linear-gradient(to top, ${Color(c.primaryColor)} 0%, ${Color(c.primaryColor).alpha(.1)} 90%, ${Color(c.primaryColor).alpha(.1)} 100%) }
    .primary-background-gradient-hover:hover { background: linear-gradient(to top, ${Color(c.primaryColor)} 0%, ${Color(c.primaryColor).alpha(.1)} 90%, ${Color(c.primaryColor).alpha(.1)} 100%) }
    .light-primary-button { background-color: ${primaryLight} !important }
    .mat-progress-spinner.primary-progress-spinner circle { stroke:  ${c.primaryColor || '#0062D0'} !important }
    .mat-checkbox .mat-ripple-element,
    .mat-radio-button .mat-ripple-element,
    .mat-slide-toggle .mat-ripple-element,
    .mat-checkbox-checked .mat-checkbox-background  { background-color: ${c.primaryColor} !important }
    .border-primary-light { border-color: ${primaryLightBorder} !important  }
    .mat-select-arrow-wrapper .mat-select-arrow { color: ${c.primaryColor || '#0062D0'} !important }
    .mat-accent .mat-slider-thumb,
    .mat-accent .mat-slider-thumb-label,
    .mat-accent .mat-slider-track-fill { background-color: ${c.primaryColor || '#0062D0'} !important }
    .mat-slider-horizontal.cdk-focused .mat-slider-thumb-label:before {
      border-color: ${c.primaryColor || '#0062D0'} transparent transparent !important;
    }
    .mat-spinner circle { stroke: ${c.primaryColor || '#0062D0'} }
    .class-item:hover .text-wrapper .arrow-btn { background-color: ${c.secondaryTextColor} }
    .mat-ink-bar { background-color: ${c.secondaryTextColor} !important }
    .class-item:hover .text-wrapper .arrow-btn mat-icon { color: ${c.secondaryColor} !important }
    .cdk-overlay-pane .mat-select-panel-wrap .mat-option.mat-active
      { border-left: 3px solid ${c.secondaryColor}; color: ${c.secondaryColor} }
    .primary-fill-hover:hover { fill:  ${c.primaryColor || '#0062D0'} !important }
    .primary-color.entity-card-item__progress-background,
    .primary-color.entity-card-item__progress-background * { color: ${primaryColor || '#0062D0'} !important }
    .ngx-pagination .current { background: ${c.primaryColor || '#0062D0'} !important; border-color: ${c.primaryColor || '#0062D0'} !important }`;

    const head = document.getElementsByTagName('head')[0];

    const oldStyleTag = head.querySelector(`#${this.styleTagId}`);
    if (oldStyleTag) {
      head.removeChild(oldStyleTag);
    }

    const style = document.createElement('style');
    style.id = this.styleTagId;
    style.appendChild(document.createTextNode(css));
    head.appendChild(style);
    this.defineCssColorProperties(c);
    this.setMetaThemeColor(themeColor);

    const fontName = 'LearingLabFont';
    const fontData = settings.general.fontPackage;

    if (fontData && fontData.content && fontData.content.length) {
      const grouped = this.groupByFileName(fontData.content);

      const fontItems: any[] = Object.keys(grouped).map(item => {
        return {
          fileList: grouped[item].sort((a: any, b: any) => this.fontFormatOrder.indexOf(a.format) > this.fontFormatOrder.indexOf(b.format) ? 1 : -1),
          weight: grouped[item][0].filename.toLowerCase().indexOf('-bold.') !== -1 ? 700 : 400,
          style: 'normal'
        };
      });

      if (fontItems && fontItems.length) {
        let fontCss = '';
        fontItems.forEach(item => {
          const urlList: string[] = [];
          item.fileList.forEach((file: any) => {
            const fileUrl = file.url || file.uri;
            urlList.push(`url('${fileUrl}')` + (file.format ? ` format('${file.format}')` : ''));
          });

          fontCss += `@font-face {
            src: ${urlList.join(',')};
            font-family: ${fontName};
            font-style: ${item.style};
            font-weight: ${item.weight};
          }`;
        });

        fontCss += `
        *:not(.material-icons):not(.icon.ph) {
          font-family: '${fontName}' !important;
        }`;

        const oldStyleFontTag = head.querySelector(`#${this.fontStyleTagId}`);
        if (oldStyleFontTag) {
          head.removeChild(oldStyleFontTag);
        }

        const fontStyle = document.createElement('style');
        fontStyle.id = this.fontStyleTagId;
        fontStyle.appendChild(document.createTextNode(fontCss));

        head.appendChild(fontStyle);
      }
    }
  }

  private defineCssColorProperties(colors: SettingsColors): void {
    const primaryLight = Color(colors.primaryColor).lighten(0.4);
    const primaryLighter = Color(colors.primaryColor).alpha(0.2);

    this.document.body.style.setProperty('--lms-primary-color', colors.primaryColor);
    this.document.body.style.setProperty('--lms-primary-color-light', primaryLight.toString());
    this.document.body.style.setProperty('--lms-primary-color-lighter', primaryLighter.toString());
    this.document.body.style.setProperty('--lms-secondary-color', colors.secondaryColor);
    this.document.body.style.setProperty('--lms-header-text-color', colors.headerIconColor);
    this.document.body.style.setProperty('--lms-primary-text-color', colors.primaryTextColor);
    this.document.body.style.setProperty('--lms-secondary-text-color', colors.secondaryTextColor);

    this.document.body.style.setProperty('--cc-btn-primary-bg', colors.primaryColor);
    this.document.body.style.setProperty('--cc-toggle-bg-on', colors.primaryColor);
    this.document.body.style.setProperty('--cc-btn-primary-hover-bg', primaryLight.toString());
  }

  private changeManifest(settings: SettingsModel): void {
    this.http.get('/manifest.webmanifest').pipe(
      tap(data => this.offlineModeService.storeRequest('/manifest.webmanifest', data))
    ).subscribe((data: any) => {    
      data.title = settings.general.platformName;
      data.short_name = settings.general.platformName;

      const url = new URL(window.location.href);
      const locationUrl = `${url.protocol}//${url.hostname}${url.port && `:${url.port}`}/`;
      data.scope = data.start_url = locationUrl;
      data.icons?.forEach((element: any) => {
        element.src = `${locationUrl}${element.src}`;
      });

      const stringManifest = JSON.stringify(data);
      const blob = new Blob([stringManifest], { type: 'application/json' });
      const manifestURL = URL.createObjectURL(blob);
      document.querySelector('#manifest-file')?.setAttribute('href', manifestURL);
    });
  }

  private toGradient(start: string, end: string, direction: string): string {
    return `linear-gradient(${direction}, ${start} 0%, ${end} 100%)`;
  }

  private groupByFileName(array: any[]) {
    return array.reduce((objectsByKeyValue, obj: any) => {
      if (!obj.filename) {
        const splitted = obj.uri?.split('/')?.reverse() || [];
        obj.filename = splitted[0];
      }

      const value = obj.filename.replace(/\.[^/.]+$/, '');
      const extension: keyof typeof FontFormat = obj.filename.match(/\.([^/.#]+)([#][^.]+)*$/)[1];
      obj.format = FontFormat[extension];
      objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
      return objectsByKeyValue;
    }, {});
  }
}
