import { CommonModule as AngularCommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Type } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { PlyrModule } from 'ngx-plyr';
import { ChipsComponent, SkeletonLoaderComponent, VideoComponent, VimeoPlayerComponent, YoutubePlayerComponent } from './components';
import { IconComponent } from './components/icon/icon.component';
import { ListLayoutSwitcherComponent } from './components/list-layout-switcher/list-layout-switcher.component';
import { dateFormats } from './constants';
import { API_URL, CLOUD_IMG_URL, DATE_FORMATS, REGEX_LIST, SETTINGS_SERVICE, STORAGE_KEYS } from './constants/injection-tokens';
import { regexList } from './constants/regex-list.const';
import { storageKeys } from './constants/storage-keys';
import { BlockBackgroundDirective, CardBackgroundDirective, CropImageDirective, MediaBackgroundDirective, PaddingDirective, SlideBackgroundDirective, AutoTextDirectionDirective } from './directives';
import { BrowserStorage } from './models/browser-storage';
import { CropImagePipe, PreSignedUrlPipe, SafeHtmlPipe, SanitizeHtmlPipe, SecondsToTimePipe, VideoEntitySourcePipe } from './pipes';
import { CustomDatePipe } from './pipes/custom-date.pipe';
import { BaseSettingsGetService, LocalStorageService } from './services';
import { ScriptLoaderService } from './services/script-loader.service';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AudioWaveformPlayerComponent } from './components/audio-waveform-player/audio-waveform-player.component';
import { RtlDirective } from './directives/rtl.directive';

const COMPONENTS = [
  VideoComponent,
  VimeoPlayerComponent,
  YoutubePlayerComponent,
  IconComponent,
  ListLayoutSwitcherComponent,
  SkeletonLoaderComponent,
  ChipsComponent,
  AudioWaveformPlayerComponent
];

const DIRECTIVES = [
  CropImageDirective,
  SlideBackgroundDirective,
  CardBackgroundDirective,
  MediaBackgroundDirective,
  BlockBackgroundDirective,
  AutoTextDirectionDirective
];

const PIPES = [
  SafeHtmlPipe,
  PaddingDirective,
  PreSignedUrlPipe,
  CustomDatePipe,
  CropImagePipe,
  SecondsToTimePipe,
  RtlDirective,
  VideoEntitySourcePipe,
  SanitizeHtmlPipe
]

const MODULES = [
  AngularCommonModule,
  MatIconModule,
  MatButtonModule,
  MatProgressSpinnerModule,
  PlyrModule,
  TranslateModule
]

@NgModule({
  declarations: [
    ...COMPONENTS,
    ...DIRECTIVES,
    ...PIPES
  ],
  imports: [
    ...MODULES
  ],
  exports: [
    ...COMPONENTS,
    ...DIRECTIVES,
    ...PIPES,
    ...MODULES
  ]
})
export class SharedLibraryModule {
  constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer, scriptLoader: ScriptLoaderService) {
    scriptLoader.loadScript('./library-explorer/assets/js/phosphor-icons/index.js', 'phosphor-icons');

    const icons = [
      'edit', 'card-portrait', 'card-landscape', 'bin', 'microphone', 'handle'
    ];

    icons.forEach(key => {
      matIconRegistry.addSvgIcon(
        `lms-${key}`,
        domSanitizer.bypassSecurityTrustResourceUrl(`/library-explorer/assets/icons/${key}.svg`)
      );
    });
  }

  public static forRoot(config: { cloudImgUrl: string, apiUrl: string, settingsClassProvider: Type<BaseSettingsGetService<any>>}): ModuleWithProviders<SharedLibraryModule> {
  
    return {
      ngModule: SharedLibraryModule,
      providers: [
        {
          provide: SETTINGS_SERVICE,
          useExisting: config.settingsClassProvider
        },
        {
          provide: REGEX_LIST,
          useValue: regexList
        },
        {
          provide: STORAGE_KEYS,
          useValue: storageKeys
        },
        {
          provide: BrowserStorage,
          useClass: LocalStorageService
        },
        {
          provide: CLOUD_IMG_URL,
          useValue: config.cloudImgUrl
        },
        {
          provide: API_URL,
          useValue: config.apiUrl
        },
        {
          provide: DATE_FORMATS,
          useValue: dateFormats
        }
      ]
    };
  }
}
