import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { finalize, shareReplay } from 'rxjs/operators';
import { BundleType } from 'library-explorer';
import { FieldName, CardColorSchemeType } from 'library-explorer';
import { PaymentResultEnum } from 'src/app/model/enums/payment-result.enum';
import { CourseService } from 'src/app/services/api/course.service';
import { ModuleService } from 'src/app/services/api/module.service';
import { fadeStaggerAnimation } from '../../constants/animations.const';
import { PayableNodeNavigationService } from '@app/services/payable-node-navigation.service';

@Component({
  selector: 'app-entity-card-row-child',
  templateUrl: './entity-card-row-child.component.html',
  styleUrls: ['./entity-card-row-child.component.scss'],
  animations: [fadeStaggerAnimation]
})
export class EntityCardRowChildComponent implements OnInit {

  @Input() hideProgress: boolean;
  @Input() skipModuleLevel: boolean;
  @Input() paymentEnabled: boolean;
  @Input() colorScheme: CardColorSchemeType;
  @Input() set entity(data: any) {
    this._entity = data;
    this.setCounterList(data);
  }
  public get entity(): any {
    return this._entity;
  }

  @Output() refresh: EventEmitter<void> = new EventEmitter();

  public childCounterVisible = false;
  public isExtended = false;
  public isExtendedListObservableLoading = true;
  public counterList = [];
  public extendedListObservable$: Observable<any>;

  public readonly fieldNames: typeof FieldName = FieldName;
  public readonly bundles: typeof BundleType = BundleType;

  private _entity: any;

  constructor(
    private readonly moduleService: ModuleService,
    private readonly courseService: CourseService,
    private readonly router: Router,
    private readonly payableNodeNavigationService: PayableNodeNavigationService
  ) { }

  ngOnInit() {
    if (!this.skipModuleLevel) {
      this.extendedListObservable$ = this.moduleService.getModulesByCourseID(this.entity.id).pipe(
        finalize(() => this.isExtendedListObservableLoading = false),
        shareReplay({bufferSize: 1, refCount: true})
      );
    }
  }

  public toggleChildCounter(event: Event): void {
    this.stopPropagation(event);
    this.childCounterVisible = !this.childCounterVisible;
  }

  public setCounterList(item: any): void {
    switch (item.bundle) {
      case BundleType.COURSE:
        this.counterList = [
          !this.skipModuleLevel && {
            name: 'COMMON.modules',
            count: item.modulesCount
          },
          {
            name: 'COMMON.lessons',
            count: item.lessonsCount
          },
          {
            name: 'COMMON.quizzes',
            count: item.quizzesCount
          }
        ];
        break;
      case BundleType.MODULE:
        this.counterList = [
          {
            name: 'COMMON.lessons',
            count: item.lessonsCount
          },
          {
            name: 'COMMON.quizzes',
            count: item.quizzesCount
          }
        ];
        break;
    }

    this.counterList = this.counterList.filter(counter => counter);
  }

  public toggleExtend(event): void {
    this.stopPropagation(event);
    this.isExtended = !this.isExtended;
  }

  public navigate(entity: any): void {
    if (entity.isLocked) {
      return;
    }

    this.payableNodeNavigationService.navigate(entity);
  }

  private stopPropagation(event: Event) {
    event.stopPropagation();
    event.preventDefault();
  }

}
