<div class="video-container" [ngClass]="videoShape" [class.not-loaded]="!isLoaded" (click)="togglePlayPause()">
  <lib-video 
    *ngIf="video || videoUrl" 
    class="video-wrapper" 
    [src]="video ? (video | videoSource | async) : videoUrl" 
    [hideControls]="true" 
    [additionalOptions]="options"
    (videoDurationInitialized)="videoLoaded()"></lib-video>

  <button mat-icon-button class="play-button" [ngClass]="theme" *ngIf="videoElement?.plyr?.paused && !hidePlayButton">
    <lib-icon icon="play"></lib-icon>
  </button>
  
  <ng-container *ngIf="!isLoaded">
    <lib-skeleton-loader height="100%" width="100%"></lib-skeleton-loader>
  </ng-container>
</div>
