import { Pipe, PipeTransform } from '@angular/core';
import { BundleType, EntityTypeId, FieldName, ProviderType, VideoModel } from '../models';
import { FileUploadApiService } from '../services';
import { Observable, of } from 'rxjs';

@Pipe({
  name: 'videoSource'
})

export class VideoEntitySourcePipe implements PipeTransform {

  constructor(
    private readonly fileUploadApiService: FileUploadApiService
  ) {}
  
  transform(video: VideoModel): Observable<string> {
    if (!video) {
      return of('');
    }
  
    if (video.stream) {
      return of(video.stream);
    }

    if (video.provider === ProviderType.AWS) {
      return this.fileUploadApiService.getPreSignedUrl(EntityTypeId.MEDIA, BundleType.VIDEO, FieldName.MEDIA_VIDEO, video.key);
    }

    return of(video.uri || video.url);
  }
}
