import { LocationStrategy, PathLocationStrategy, ViewportScroller } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StorageMap, StorageModule } from '@ngx-pwa/local-storage';
import { TranslateCompiler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { GtagModule } from 'angular-gtag';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { HotkeyModule } from 'angular2-hotkeys';
import { CookieService } from 'ngx-cookie-service';
import { DisqusModule } from 'ngx-disqus';
import { NgxMaskModule } from 'ngx-mask';
import { NG_SCROLLBAR_OPTIONS } from 'ngx-scrollbar';
import { ToastrModule } from 'ngx-toastr';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CertificateModule } from './components/certificate/certificate.module';
import { FooterModule } from './components/commons/footer/footer.module';
import { MaintenanceModule } from './components/maintenance/maintenance.module';
import { ToastComponent } from './components/toast/toast.component';
import { TranslationKeyToggleComponent } from './components/translation-key-toggle/translation-key-toggle.component';
import { GlobalErrorHandler } from './handlers/global-error.handler';
import { ClientIdentificationInterceptor } from './interceptors/client-identification.interceptor';
import { HttpErrorHandlingInterceptor } from './interceptors/http-error-handling.interceptor';
import { CustomTranslationLoaderService } from './services/custom-translation-loader.service';

import { InitializeService } from './services/initialize.service';
import { LanguageService } from './services/language.service';
import { ChatSharedModule } from './shared/components/chat-shared/chat-shared.module';
import { SharedModule } from './shared/shared.module';
import { ContentVisibilityInterceptor } from './interceptors/content-visibility.interceptor';
import { 
  LocalStorageService, 
  MaintenanceInterceptor,
  storageKeys, 
  BrowserStorage, 
  STORAGE_KEYS, 
  SharedLibraryModule, 
  SharedTranslateCompilerService,
  PreSignedUrlInterceptor
} from 'library-explorer';
import { ApplicationVersionInterceptor } from './interceptors/application-version.interceptor';
import { NgxMatomoTrackerModule, MatomoInitializationMode } from '@ngx-matomo/tracker';
import { NgxMatomoRouterModule } from '@ngx-matomo/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MatomoCustomRouteInterceptor } from './interceptors/matomo-custom-route.interceptor';
import { BranchIdentificationInterceptor } from './interceptors/branch-identification.interceptor';
import { AcceptLanguageInterceptor } from './interceptors/accept-language.interceptor';
import { OfflineRequestInterceptor } from './interceptors/offline-request.interceptor';
import { environment } from '@env/environment';
import { Router, Scroll, Event } from '@angular/router';
import { filter, pairwise } from 'rxjs/operators';
import { SettingsService } from './services/settings.service';
import { SkipTokenInterceptor } from './interceptors/skip-token.interceptor';
import { LoadingTokenInterceptor } from './interceptors/loading-token.interceptor';
import { LoadingToastComponent } from './components/loading-toast/loading-toast.component';


export function init_app(initializeService: InitializeService, injector: Injector) {
  return () => initializeService.init(injector);
}

const disableAnimations = !('animate' in document.documentElement)
  || (navigator && /iPhone OS (8|9|10|11|12|13)_/.test(navigator.userAgent));

@NgModule({
  declarations: [
    AppComponent,
    ToastComponent,
    TranslationKeyToggleComponent,
    LoadingToastComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule.withConfig({ disableAnimations }),
    SharedModule,
    ChatSharedModule,
    HttpClientModule,
    DisqusModule.forRoot('thelearning-lab'),
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-center',
      preventDuplicates: true,
      toastComponent: ToastComponent,
    }),
    ServiceWorkerModule.register('custom-ngsw-worker.js', { enabled: true, registrationStrategy: 'registerImmediately' }),
    StorageModule.forRoot({ IDBNoWrap: true }),
    GtagModule.forRoot({ trackingId: '', trackPageviews: true }),
    AngularSvgIconModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomTranslationLoaderService,
        deps: [HttpClient, Injector, StorageMap]
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: SharedTranslateCompilerService
      }
    }),
    NgxMatomoTrackerModule.forRoot({
      mode: MatomoInitializationMode.AUTO_DEFERRED,
    }),
    NgxMatomoRouterModule.forRoot({
      interceptors: [MatomoCustomRouteInterceptor],
    }),
    FooterModule,
    NgxMaskModule.forRoot(),
    CertificateModule,
    HotkeyModule.forRoot(),
    MaintenanceModule,
    SharedLibraryModule.forRoot({ cloudImgUrl: environment.cloudImgUrl, apiUrl: environment.apiUrl, settingsClassProvider: SettingsService })
  ],
  providers: [
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    CookieService,
    InitializeService,
    LanguageService,
    {
      provide: STORAGE_KEYS,
      useValue: storageKeys
    },
    {
      provide: BrowserStorage,
      useClass: LocalStorageService
    },
    {
      provide: APP_INITIALIZER,
      useFactory: init_app,
      deps: [InitializeService, Injector],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingTokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: OfflineRequestInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorHandlingInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ClientIdentificationInterceptor,
      multi: true
    },

    {
      provide: HTTP_INTERCEPTORS,
      useClass: BranchIdentificationInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MaintenanceInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ContentVisibilityInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApplicationVersionInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AcceptLanguageInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SkipTokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PreSignedUrlInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
    {
      provide: NG_SCROLLBAR_OPTIONS,
      useValue: {
        scrollAuditTime: 20
      }
    }
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
  constructor(router: Router, viewportScroller: ViewportScroller) {
    router.events.pipe(
      filter((event: Event) => event instanceof Scroll),
      pairwise()
    ).subscribe(([previousEvent, event]: [Scroll, Scroll]) => {
      const scrollElement = document.getElementById('full-layout-scroll-section');
      
      if (event.position) {
        if (scrollElement) {
          scrollElement.scrollTo({left: event.position[0], top: event.position[1]})
        } else {
          viewportScroller.scrollToPosition(event.position);
        }
        return;
      }

      if (event.anchor) {
        // anchor navigation
        viewportScroller.scrollToAnchor(event.anchor);
        return;
      }

      const prevUrl = previousEvent?.routerEvent?.urlAfterRedirects?.split('?')[0];
      const currentUrl = event?.routerEvent?.urlAfterRedirects?.split('?')[0];
    
      if (prevUrl !== currentUrl) {
        if (scrollElement) {
          scrollElement.scrollTo({left: 0, top: 0})
        } else {
          viewportScroller.scrollToPosition([0, 0]);
        }
      }
    });

  }
}
